



























































import { Component, Vue, Watch } from "vue-property-decorator";
import DialogView from "@/components/Dialog.vue";
import { API } from "@/services/API";

type TFormFieldValidator = (v: string) => boolean;
type TFormFieldRule = (v: string) => { observed: boolean; error: string };

const createFormFieldRule = (validator: TFormFieldValidator, text: string): TFormFieldRule => {
  const prefix = "Пожалуйста, введите";
  return (v: string) => {
    const observed = validator(v);
    const error = observed ? "" : prefix + " " + text;
    return { observed, error };
  };
};

type TFormFieldRuleConstructor = (text: string) => TFormFieldRule;
type TFormFieldRuleMap<T = TFormFieldRuleConstructor> = {
  filled: T;
  email: T;
};

const rules: TFormFieldRuleMap = {
  filled: (text) => createFormFieldRule((v) => !!v, text),

  email: (text) =>
    createFormFieldRule((v) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(v);
    }, text),
};

type TFormField = {
  value: string;
  rules: TFormFieldRule[];
  error: {
    value: string;
    shown: boolean;
  };
};

type TDialog = {
  value: boolean;
  props: {
    icon?: string;
    title?: string;
    okText?: string;
    cancelText?: string;
    onOk?: () => void;
    onCancel?: () => void;
    loading?: boolean;
  };
};

@Component({
  components: {
    DialogView,
  },
})
export default class ContactUs extends Vue {
  $refs!: {
    form: HTMLFormElement;
  };

  protected dialogConfirmation: TDialog = {
    value: false,
    props: {
      title: "Подтвердите\nотправку сообщения",
      onOk: () => {
        this.dialogConfirmation.props.loading = true;
        const data = this.getData();
        API.post("/Mail/MailFormToItway", data)
          .then((resp) => {
            if (resp.data?.result === "OK") {
              this.name.value = "";
              this.email.value = "";
              this.message.value = "";
              setTimeout(() => (this.dialogSuccess.value = true), 500);
            } else this.dialogError.value = true;
          })
          .catch(() => setTimeout(() => (this.dialogError.value = true), 500))
          .finally(() => {
            this.dialogConfirmation.props.loading = false;
            this.dialogConfirmation.value = false;
          });
      },
      onCancel: () => (this.dialogConfirmation.value = false),
      loading: false,
    },
  };

  protected dialogSuccess: TDialog = {
    value: false,
    props: {
      icon: "success",
      title: "Спасибо!\nВаше сообщение отправлено!",
      okText: "Закрыть",
      onOk: () => (this.dialogSuccess.value = false),
    },
  };

  protected dialogError: TDialog = {
    value: false,
    props: {
      icon: "error",
      title: "Ошибка!\nПопробуйте еще раз!",
      okText: "Закрыть",
      onOk: () => (this.dialogError.value = false),
    },
  };

  protected name: TFormField = {
    value: "",
    rules: [rules.filled("имя")],
    error: {
      value: "",
      shown: false,
    },
  };

  @Watch("name.value")
  protected nameOnChange() {
    if (this.name.error.shown) this.name.error.shown = false;
  }

  @Watch("email.value")
  protected emailOnChange() {
    if (this.email.error.shown) this.email.error.shown = false;
  }

  @Watch("message.value")
  protected messageOnChange() {
    if (this.message.error.shown) this.message.error.shown = false;
  }

  protected email: TFormField = {
    value: "",
    rules: [rules.filled("Email"), rules.email("корректный Email")],
    error: {
      value: "",
      shown: false,
    },
  };

  protected message: TFormField = {
    value: "",
    rules: [rules.filled("сообщение")],
    error: {
      value: "",
      shown: false,
    },
  };

  protected validateData() {
    const { name, email, message } = this;
    const fields = [name, email, message];
    fields.forEach((field) => {
      const results = field.rules.map((rule) => rule(field.value));
      const error = results.find(({ observed }) => !observed)?.error;
      if (error) {
        field.error.value = error;
        field.error.shown = true;
      } else {
        field.error.value = "";
        field.error.shown = false;
      }
    });
    const validated = fields.every(({ error: { shown } }) => !shown);
    return validated;
  }

  protected getData() {
    const { name, email, message } = this;
    const data = {
      name: name.value,
      email: email.value,
      message: message.value,
    };
    return data;
  }

  protected onSubmit() {
    const validated = this.validateData();
    if (validated) this.dialogConfirmation.value = true;
  }
}

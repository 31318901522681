






















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class DialogView extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value!: boolean;
  @Prop({ type: String }) readonly icon?: string;
  @Prop({ type: String }) readonly title?: string;
  @Prop({ type: String, default: "Подтвердить" }) readonly okText!: string;
  @Prop({ type: String, default: "Отмена" }) readonly cancelText!: string;
  @Prop({ type: Function }) readonly onOk?: () => void;
  @Prop({ type: Function }) readonly onCancel?: () => void;
  @Prop({ type: Boolean }) readonly loading?: boolean;

  protected get image() {
    const { icon } = this;
    if (!icon) return null;
    return {
      src: require("@/assets/icons/" + icon + ".svg"),
      alt: "",
    };
  }

  protected get opened() {
    return this.value;
  }
  protected set opened(v) {
    this.$emit("input", v);
  }

  @Watch("opened", { immediate: true })
  protected openedOnChange(v: boolean) {
    document.documentElement.style.overflow = v ? "hidden" : "hidden auto";
  }
}

import axios from "axios";

const isProd = process.env.NODE_ENV === "production";

const config = {
  baseURL: isProd ? "http://mailapi.itway.company:45000" : "http://localhost:5000",
  timeout: 3,
};

const { baseURL, timeout } = config;

export const API = axios.create({
  baseURL: baseURL + "/api",
  timeout: timeout * 1000,
});
